import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';

function autoSave(store) {
    mobx.autorun(() => {
        const json = JSON.stringify(store.admin_data);
        localStorage.setItem('APP_ADMIN', json)
    });
}

class StoreUi {
    admin_interface = { 
        isDialogFeedbackOpen: false,
        isDialogMailingOpen: false
    }
    admin_data = {
        admin_loggedin: false
    }
    settings_list = {}
    settingsAdmin_list = {}
    admin_loggedin = false;
    active_tag = '';
    active_mailing = '';
    active_mailing_title = '';
    form_feedback = '';
    format_date = "dd D MMM YYYY HH:mm";
    format_date_simple = "D MMM YYYY";
    list_nurs = [];
    newsletter = {}
    mailing_exists = false
    mailing_sent_full = false
    mailing_sent_email = false
    mailing_sent_test = false
    mailing_sent_test_new = false
    nav_categories = []
    nav_catnurs = []
 
    constructor() {
        this.getSettings();
        this.getAdminDataFromLS();
        autoSave(this);
    }

    save(json) {
        this.saveToLS(json);
    }

    saveToLS(json) {
        localStorage.setItem('APP_ADMIN', json)
    }

    getAdminDataFromLS() {
        let data;
        let user_id = 0;
        if (localStorage.getItem('APP_ADMIN')) {
            data = JSON.parse(localStorage.getItem('APP_ADMIN'));
            if (data) {
                this.admin_data = data;
            } 
        } 
        return user_id;
    }

    openDialogFeedback = () => {
        this.admin_interface.isDialogFeedbackOpen = true;
    }

    closeDialogFeedback = () => {
        this.admin_interface.isDialogFeedbackOpen = false;
    }

    openDialogMailing = (wk, week_dir, mailing, title) => {
        this.active_mailing = mailing;
        this.active_mailing_title = title;
        this.active_mailing_wk = wk;
        this.active_mailing_week_dir = week_dir;
        this.admin_interface.isDialogMailingOpen = true;
    }

    closeDialogMailing = () => {
        this.active_mailing = '';
        this.active_mailing_title = '';
        this.active_mailing_wk = '';
        this.active_mailing_week_dir = '';
        this.admin_interface.isDialogMailingOpen = false;
    }

    async checkLoginAdmin() {
        try {
            //const returnData = await content.getSettings();
            runInAction(() => {
                this.admin_loggedin = true;
                this.admin_data.admin_loggedin = true;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    setLoginState(state) {
        this.admin_loggedin = state;
    }

    async getSettings() {
        let settingsList;
        try {
            const returnData = await content.getSettings();
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    this.settings_list[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getSettingsAdmin() {
        let settingsList;
        try {
            const returnData = await content.getSettingsAdmin();
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    this.settingsAdmin_list[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async updateSettings(formControls) {
        let settingsList;
        try {
            const returnData = await content.updateSettings(formControls);
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    //this.settings_list[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async updateSettingsAdmin(formControls) {
        let formData = [];
        let items = {}
        
        Object.keys(formControls).map((key, i) => {
            //item = {};
            items[key] = formControls[key].value;
            //formData.push(item);
        });

        try {
            const returnData = await content.updateSettingsAdmin(items);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async setNewsletter(data) {
        try {
            const returnData = await content.setNewsletter(data);
            runInAction(() => {
                
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async setNewsletterTitle(data) {
        try {
            const returnData = await content.setNewsletterTitle(data);
            runInAction(() => {
                
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getNavigationCategories() {
        let cats = [];
        try {
            const returnData = await content.getNavigationCategories();
            runInAction(() => {
                console.log('SbC categories all::', returnData)
                cats = returnData.data;
                this.nav_categories = returnData.data;
                return cats;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cats;
    }

    async getListCatNURs() {
        try {
            const returnData = await content.getListCatNURs();
            runInAction(() => {
                console.log('SbC cat nurs::', returnData)
                this.nav_catnurs = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getListNURs() {
        let nurs = [];
        try {
            const returnData = await content.getListNURs();
            runInAction(() => {
                nurs = returnData.data;
                this.list_nurs = returnData.data;
                return nurs;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return nurs;
    }

    async getCountForNUR(nur) {
        let count = 0;
        let data = {
            nur: nur
        }
        try {
            const returnData = await content.getCountForNUR(data);
            runInAction(() => {
                count = returnData.data;
                return count;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return count;
    }

    async countBooks() {
        let returnData;
        try {
            returnData = await content.clearCountBooks();
            runInAction(async() => {
                returnData = await content.countBooks();
                this.form_feedback = 'De telling is aangepast';
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getNewsletter(tag) {
        let newsletter = {};
        try {
            const returnData = await content.getNewsletter(tag);
            runInAction(() => {
                newsletter = returnData.data[0];
                this.active_mailing_title = newsletter.title;
                return newsletter;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return newsletter;
    }

    async checkMailingExists(dir) {
        let data = {
            dir: dir,
            file: 'wk2034'
        }
        let exists;
        try {
            const returnData = await content.checkMailingExists(data);
            runInAction(() => {
                if ('success' in returnData.data) {
                    this.mailing_exists = true;
                    exists = true;
                } else {
                    this.mailing_exists = false;
                    exists = false;
                }
                return exists;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return exists;
    }

    setActiveTag(tag) {
        this.active_tag = tag;
    }

    async sendMailing(scope, wk, week_dir, file, title, to_firstname, to_email) {
        const data = {
            scope: scope,
            wk: wk,
            week_dir: week_dir,
            file: file,
            title: title,
            to_firstname: to_firstname,
            to_email: to_email
        }

        try {
            //const returnData = await content.sendMailing(data);
            const returnData = await content.sendMailingNew(data);
            runInAction(() => {
                if ('success' in returnData.data[0]) {
                    if (data.scope === 'test') {
                        this.mailing_sent_test = true;
                    } 
                    if (data.scope === 'email') {
                        this.mailing_sent_email = true;
                    }
                    if (data.scope === 'full') {
                        this.mailing_sent_full = true;
                    }
                } else {
                    if (data.scope === 'test') {
                        this.mailing_sent_test = true;
                    } 
                    if (data.scope === 'email') {
                        this.mailing_sent_email = true;
                    }
                    if (data.scope === 'full') {
                        this.mailing_sent_full = true;
                    }
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async sendMailingNew(scope, wk, week_dir, to_firstname, to_email) {
        const data = {
            scope: scope,
            wk: wk,
            week_dir: week_dir,
            to_firstname: to_firstname,
            to_email: to_email
        }

        try {
            const returnData = await content.sendMailingNew(data);
            runInAction(() => {
                if ('success' in returnData.msg) {
                    if (data.scope === 'test') {
                        this.mailing_sent_test_new = true;
                    } 
                    if (data.scope === 'email') {
                        this.mailing_sent_email = true;
                    }
                    if (data.scope === 'full') {
                        this.mailing_sent_full = true;
                    }
                } else {
                    if (data.scope === 'test') {
                        this.mailing_sent_test_new = true;
                    } 
                    if (data.scope === 'email') {
                        this.mailing_sent_email = true;
                    }
                    if (data.scope === 'full') {
                        this.mailing_sent_full = true;
                    }
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async addCategory(form_controls) {
        let data = {
            id: form_controls.id.value,
            name: form_controls.name.value,
            display_value: form_controls.display_value.value,
            is_main_category: form_controls.is_main_category.value,
            main_category: form_controls.main_category.value,
        }
        try {
            const returnData = await content.addCategory(data);
            runInAction(() => {
                this.getNavigationCategories();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async addNUR(form_controls) {
        let data = {
            nur: form_controls.nur.value,
            category: form_controls.category.value
        }
        try {
            const returnData = await content.addNUR(data);
            runInAction(() => {
                this.getListCatNURs();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async deleteCategory(is_main_category, cat, subcat) {
        let subcategory = subcat ? subcat : 0;
        let data = {
            is_main_category: is_main_category,
            category: cat,
            subcategory: subcategory
        }
        try {
            const returnData = await content.deleteCategory(data);
            runInAction(() => {
                this.getNavigationCategories();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async deleteNUR(category, nur) {
        let data = {
            nur: nur,
            category: category
        }
        try {
            const returnData = await content.deleteNUR(data);
            runInAction(() => {
                this.getListCatNURs();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

}

decorate(StoreUi, {
    active_mailing: observable,
    active_mailing_title: observable,
    active_mailing_wk: observable,
    active_mailing_week_dir: observable,
    active_tag: observable,
    admin_data: observable,
    admin_interface: observable,
    admin_loggedin: observable,
    form_feedback: observable,
    list_nurs: observable,
    mailing_exists: observable,
    mailing_sent_full: observable,
    mailing_sent_email: observable,
    mailing_sent_test: observable,
    mailing_sent_test_new: observable,
    nav_categories: observable,
    nav_catnurs: observable,
    newsletter: observable,
    settings_list: observable,
    settingsAdmin_list: observable,
    addCategory: action,
    checkLoginAdmin: action,
    checkMailingExists: action,
    closeDialogFeedback: action,
    closeDialogMailing: action,
    countBooks: action,
    deleteNUR: action,
    getCountForNUR: action,
    getListCatNURs: action,
    getListNURs: action,
    getNavigationCategories: action,
    getNewsletter: action,
    getSettings: action,
    openDialogFeedback: action,
    openDialogMailing: action,
    sendMailing: action,
    setLoginState: action,
    setNewsletter: action
})

export default StoreUi;