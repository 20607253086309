import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import moment from "moment";

import * as partners from '../communicator/partners';

class StorePartners {
    list_categories = [];
    form_feedback = '';

    constructor() {
        this.getPartnerFees();
    }

    async getPartner(id) {
        let partner;

        try {
            let returnData = await partners.getPartner(id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    partner = returnData.data[0];
                } else {

                } 
                return partner;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return partner;
    }

    async getPartnerSummary(id) {
        let partner;

        try {
            let returnData = await partners.getPartnerSummary(id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    partner = returnData.data[0];
                } else {

                } 
                return partner;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return partner;
    }

    async getPartnerChecks(id) {
        let result;

        try {
            let returnData = await partners.getPartnerChecks(id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    result = returnData.data[0];
                    console.log('SbC partner checks', id, result)
                } else {

                } 
                return result;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return result;
    }

    async getReportPartnersOverview(sortValue, sortOrder) {
        let result;

        try {
            const returnData = await partners.getReportPartnersOverview(sortValue, sortOrder);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getReportPartnerChecksOverview(sortValue, sortOrder) {
        let result;

        try {
            const returnData = await partners.getReportPartnerChecksOverview(sortValue, sortOrder);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getReportPartners2() {
        let result;

        try {
            const returnData = await partners.getReportPartners();
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async initPartners() {
        let result;

        try {
            const returnData = await partners.initPartners();
            runInAction(() => {
                result = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getPartnerOpenCommissions(id) {
        let result;

        try {
            const returnData = await partners.getPartnerOpenCommissions(id);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getPartnerAllSales(id) {
        let result;
        try {
            const returnData = await partners.getPartnerAllSales(id);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getPartnerAllSalesWithAbort(id, controller, signal) {
        let result;
        try {
            const returnData = await partners.getPartnerAllSalesWithAbort(id, controller, signal);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getPartnerPayouts($id) {
        let result;

        try {
            const returnData = await partners.getPartnerPayouts($id);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    calcTotalPayouts = (payouts) => {
        let total = 0;
        payouts.map((payout) => {
            if (!payout.description.startsWith("Correctie")) {
                total = total + parseFloat(payout.amount);
            }
        })

        total = total.toFixed(2);

        return total;
    }

    calcTotalSales = (partner, sales) => {
        let priceDisplay;
        let total = 0;
        let price = 0;
        
        sales.map((sale) => {
            price = this.handleSale(partner, sale);
            price = price.toString().replace(',','.');
            total = total + parseFloat(price);
        })

        priceDisplay = total.toFixed(2);
        priceDisplay = priceDisplay.toString().replace('.',',');
        
        return priceDisplay;
    }

    calcTotalCommissions = (partner, sales) => {
        let priceDisplay;
        let total = 0;
        let commission = 0;
        
        sales.map((sale) => {
            commission = this.handleCommission(partner, sale, 6);
            commission = commission.toString().replace(',','.');
            //commission = Number(commission).toFixed(2);
            total = total + parseFloat(commission);
        })

        priceDisplay = total.toFixed(2);
        priceDisplay = priceDisplay.toString().replace('.',',');
        
        return priceDisplay;
    }

    handleSale = (partner, sale) => {
        let priceDisplay;
        let decimals = 2;
        let price = sale.price * sale.amount;

        if (this.handleVAT(partner, sale)) {
            //SbC if VAT-nummer then show incl VAT
            priceDisplay = this.formatNumber(price, decimals);
        } else {
            //SBC if no VAT-nummer then show excl VAT
            priceDisplay = price / (1 + (sale.vat/100));
            priceDisplay = this.formatNumber(priceDisplay, decimals);
        }
        
        priceDisplay = priceDisplay.toString().replace('.',',');

        return priceDisplay;
    }

    calcSubTotal = (partner, sales, ii) => {
        let result;
        let rowTotal;
        let discount;
        let commission;

        discount = 30;

        let subtotal = 0;

        sales.map((sale, i) => {
            

            if (i <= ii) {
                commission = this.handleCommission(partner, sale, 2);
                commission = parseFloat(commission.replace(/,/g, '.'));
                //rowTotal = sales[i].price * sales[i].amount * (discount / 100);
                subtotal = subtotal + commission;
            }
        })

        //result = sales[i].price * sales[i].amount;
        result = subtotal;

        return result;
    }

    handleCommission = (partner, sale, decimals) => {
        let priceDisplay;
        let commission = 0;
        let commissionPerc = 0;
        let partnerFee = 10;
        let exception = false;

        const idx = this.partnerFees
            .findIndex((item) => parseInt(item.bookId) === parseInt(sale.bookId));
        
        if (idx !== -1) {
            partnerFee = this.partnerFees[idx];
            exception = true;
        }

        if (parseInt(sale.commissionSB) === 1) {
            // SbC exception perc for example for event tickets
            if (exception) {
                commissionPerc = partnerFee.percFee;
            } else {
                //SbC sale SB
                if (sale.commission_current !== '' && sale.commission_current !== null) {
                    commissionPerc = sale.commission_current;
                } else {
                    commissionPerc = partner.commission;
                }
            }
        } else {
            //SbC sale 3rd party
            if (sale.commission_3party_current !== '' && sale.commission_3party_current !== null) {
                commissionPerc = sale.commission_3party_current;
            } else {
                commissionPerc = partner.commission_3party;
            }
        }

        commission = sale.amount * sale.price * commissionPerc / 100; 
        if (this.handleVAT(partner, sale)) {
            priceDisplay = this.formatNumber(commission, decimals);
        } else {
            priceDisplay = commission / (1 + (sale.vat/100));
            priceDisplay = this.formatNumber(priceDisplay, decimals);
        }
      
        priceDisplay = priceDisplay.toString().replace('.',',');

        return priceDisplay;
    }

    handleVAT = (partner, sale) => {
        let hasVat = false;

        const dateSale = sale.date;

        if (partner.vat_nr && partner.vat_nr !== undefined && partner.vat_nr !== '') {
            if (partner.vatNumberFrom && partner.vatNumberFrom.date) {
                if (moment(dateSale.date).isBefore(moment(partner.vatNumberFrom.date))) {
                    hasVat = false;
                } else {
                    hasVat = true;
                }
            } else {
                hasVat = true;
            }
        } else {
            if (partner.vatNumberUntil && partner.vatNumberUntil.date) {
                if (partner.vatNumberFrom && partner.vatNumberFrom.date) {
                    if (moment(dateSale.date).isBefore(moment(partner.vatNumberUntil.date)) && moment(dateSale.date).isAfter(moment(partner.vatNumberFrom.date))) {
                        hasVat = true;
                    } else {
                        hasVat = false;
                    }
                } else {
                    if (moment(dateSale.date).isBefore(moment(partner.vatNumberUntil.date))) {
                        hasVat = true;
                    } else {
                        hasVat = false;
                    }
                }
            }
        }

        return hasVat;
    }

    formatNumber = (price, decimals) => {
        let priceDisplay = parseFloat(price).toFixed(decimals);
        priceDisplay = priceDisplay.toString().replace('.',',');
        
        return priceDisplay;
    }

    addPartnerPayout = async(id, formControls) => {
        const data = {
            settleDate: formControls.settleDate.value,
            payout: formControls.payout.value,
            description: formControls.description.value
        }

        let result;

        try {
            const returnData = await partners.addPartnerPayout(id, data);
            runInAction(() => {
                result = returnData.data;
                this.form_feedback = returnData.data[0];
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    getPartnerFees = async() => {
        let result;

        try {
            const returnData = await partners.getPartnerFees();
            runInAction(() => {
                result = returnData.data;
                this.partnerFees = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    updatePartnerChecks = async(id, key, value) => {
        let result;

        try {
            const returnData = await partners.updatePartnerChecks(id, key, value);
            runInAction(() => {
                result = returnData.data;
                console.log('SbC checks update return', result)
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

}

decorate(StorePartners, {
    partnerFees: observable,
    form_feedback: observable,
    getPartner: action,
    getReportPartners: action,
    getPartnerPayouts: action,
    getPartnerAllSales: action,
    getPartnerOpenCommissions: action,
    calcTotalPayouts: action
})

export default StorePartners;